import React from 'react'
import Layout from "../containers/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../containers/SEO"
import {
  ExclamationCircleIcon ,
} from '@heroicons/react/solid'


function EmailOne() {
  return <StaticImage src="../images/email-1.png" alt="An email example showing 4 manipulative tactics from JamieHarrison.com" placeholder="blurred"/>
}
function EmailTwo() {
  return <StaticImage src="../images/email-2.png" alt="An email example showing 2 manipulative tactics" />
}
function EmailStepOne() {
  return <StaticImage src="../images/email-step-1.png" alt="An email example showing Changed ‘From’ Field" />
}
function EmailStepTwo() {
  return <StaticImage src="../images/email-step-2.png" alt="An email example showing " />
}
function EmailStepThree() {
  return <StaticImage src="../images/email-step-3.png" alt="An email example showing " />
}
function EmailStepFour() {
  return <StaticImage src="../images/email-step-4.png" alt="An email example showing " />
}
function EmailStepFive() {
  return <StaticImage src="../images/email-step-5.png" alt="An email example showing " />
}
function EmailStepSix() {
  return <StaticImage src="../images/email-step-6.png" alt="An email example showing " />
}


function ManipulativeTactics() {
  return (
    <Layout>
      <Seo title="Manipulative Tactics" />
      <section>
        <div className="container !pt-0">
          <h1 className="border-b-4 border-blue-400">Manipulative tactics</h1>
          <p className="max-w-2xl">We found six tactics that senders use to manipulate recipients into opening emails. The typical sender used at least one manipulative tactic in about 43% of their emails. Most senders — 99% — use them at least occasionally.</p>
          <p className="max-w-2xl">We found 200 entities that disclosed our email address with other entities but the majority (114/200) had no privacy policy and only about a quarter (48/200) disclosed their email sharing in the privacy policy.</p>
          <div className="hidden mt-16 lg:grid lg:grid-cols-2 gap-x-8">
            <div className='flex items-center justify-center'>
             <EmailOne />
            </div>
            <div className="flex items-center justify-center px-16">
              <ol>
                <li className="relative py-6 group">
                  <span className="tactic-dot tactic-dot-1">1</span>
                  <div className="tactic tactic-1">
                    <p className="text-lg"><strong>Obscured name dark pattern</strong></p>
                    <p>“From” field has been changed from the actual sender. Many email clients will only display the beginning of a long name before being cut off</p>
                  </div>
                </li>
                <li className="relative py-6 group">
                  <span className="tactic-dot tactic-dot-2">2</span>
                  <div className="tactic tactic-2">
                    <p className="text-lg"><strong>Urgency clickbait</strong></p>
                    <p>Deadline that makes this email seem urgent and extremely pressing.</p>
                  </div>
                </li>
                <li className="relative py-6 group">
                  <span className="tactic-dot tactic-dot-3">3</span>
                  <div className="tactic tactic-3">
                    <p className="text-lg"><strong>Re: / Fwd: dark pattern</strong></p>
                    <p>“Re:” placed in the subject line to make this email look lie a reply to a previous email, when it is not.</p>
                  </div>
                </li>
                {/* <li className="relative py-6 group">
                  <span className="tactic-dot tactic-dot-4">4</span>
                  <div className="tactic tactic-4">
                    <p className="text-lg"><strong>Forged Counters</strong></p>
                    <p>This number continuously ticks downwards from 549 at random increments once the email is opened</p>
                  </div>
                </li> */}
              </ol>
            </div>
          </div>

          <div className="hidden mt-24 lg:grid lg:grid-cols-2 gap-x-8">
            <div className="flex items-center justify-center px-16 test">
              <ol className="tactics-list">
                <li className="relative py-6 group">
                  <span className="tactic-dot tactic-dot-4">4</span>
                  <div className="tactic tactic-4">
                    <p className="text-lg"><strong>Ongoing thread dark pattern</strong></p>
                    <p>“From” field changed to make it appear as if you have responded to this email before, and it is part of an ongoing thread.</p>
                  </div>
                </li>
                <li className="relative py-6 group">
                  <span className="tactic-dot tactic-dot-5">5</span>
                  <div className="tactic tactic-5">
                    <p className="text-lg"><strong>Sensationalism clickbait</strong></p>
                    <p>Creating exaggeration, shock, and hyperbole to make the email seem controversial.</p>
                  </div>
                </li>
                <li className="relative py-6 group">
                  <span className="tactic-dot tactic-dot-6">6</span>
                  <div className="tactic tactic-6">
                    <p className="text-lg"><strong>Forward referencing clickbait</strong></p>
                    <p>Inducing anticipation and curiosity by withholding information to nudge the reader to click on the email.</p>
                  </div>
                </li>
              </ol>
            </div>
            <div className="relative flex items-center justify-center">
              <EmailTwo />
            </div>
          </div>

          <div className="px-4 pt-16 lg:px-8 lg:hidden">
            <div className='pb-6 mb-16 border-b-2 border-gray-100'>
              <EmailStepOne />
              <div className="px-8 py-4">
                <p className="text-lg"><strong>Changed ‘From’ Field</strong></p>
                <p>“From” field has been changed from the actual sender. Many email clients will only display the beginning of a long name before being cut off</p>
              </div>
            </div>
            <div className='pb-6 mb-16 border-b-2 border-gray-100'>
              <EmailStepTwo />
              <div className="px-8 py-4">
                <p className="text-lg"><strong>Fake Deadlines</strong></p>
                <p>Deadline to make this email seem urgent and extremely pressing</p>
              </div>
            </div>
            <div className='pb-6 mb-16 border-b-2 border-gray-100'>
              <EmailStepThree />
              <div className="px-8 py-4">
                <p className="text-lg"><strong>Forged Reply</strong></p>
                <p>“Re:” placed to make this email look lie a reply, when it is not</p>
              </div>
            </div>
            <div className='pb-6 mb-16 border-b-2 border-gray-100'>
              <EmailStepFour />
              <div className="px-8 py-4">
                <p className="text-lg"><strong>Forged Counters</strong></p>
                <p>This number continuously ticks downwards from 549 at random increments once the email is opened</p>
              </div>
            </div>
            <div className='pb-6 mb-16 border-b-2 border-gray-100'>
              <EmailStepFive />
              <div className="px-8 py-4">
                <p className="text-lg"><strong>Fake Email Threads</strong></p>
                <p>“From” field changed to make it appear as if you have responded to this email before, and it is part of an ongoing thread.</p>
              </div>
            </div>
            <div>
              <EmailStepSix />
              <div className="px-8 py-4">
                <p className="text-lg"><strong>Dishonest Subject Phrasing</strong></p>
                <p>Subject phrasing intended to make it appear as if reader’s money is being held up</p>
              </div>
            </div>
          </div>



        </div>
      </section>
    </Layout>
  )
}

export default ManipulativeTactics
